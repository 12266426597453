import React from "react"
import { MainLayout } from "../../layout"
import PageBody from "../../components/Volunteer"

export default function VolunteerPage() {
  return (
    <MainLayout>
      <PageBody />
    </MainLayout>
  )
}
